import React from 'react';
import moment from 'moment-timezone';

export const AuthContext = React.createContext({
  authenticated: false,
  setAuthenticated: (authenticated) => {},
});

export const TimezoneContext = React.createContext({
  timezone: moment.tz.guess(true),
  setTimezone: (timezone) => {},
});
