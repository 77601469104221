import { useMediaQuery, withStyles } from "@material-ui/core";
import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  FunctionField,
  ChipField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  ArrayField,
  ReferenceField,
  AutocompleteInput,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";
import moment from "moment-timezone";
import "./videos.css";

const styles = {
  image: { maxHeight: "5rem", maxWidth: "10rem" },
  root: {},
};

const VideoFilter = [
  <TextInput source="search" label="Search Videoname" alwaysOn />,
  <ReferenceInput
    label="Video Categories"
    source="categoryIds"
    reference="categories"
    alwaysOn
    perPage={1000}
    sort={{ field: "createdDate", order: "DESC" }}
    className={"videoCategoryFilter"}
  >
    <AutocompleteInput optionText="categoryName" />
  </ReferenceInput>,
  // <BooleanInput source="sort" defaultValue={false} fullWidth alwaysOn />,
];

export default withStyles(styles)(({ classes, permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={<BulkActionButtons permissions={permissions} />}
      filters={VideoFilter}
      sort={{ field: "updatedDate", order: "-1" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.videoTitle}
          secondaryText={(record) => record.videoSubtitle}
          // tertiaryText={(record) => record.id}
          leftIcon={(record) => {
            if (record.thumbnailUrl != undefined) {
              return (
                <img
                  src={record.thumbnailUrl}
                  width="60px"
                  style={{ marginRight: "8px" }}
                />
              );
            } else {
              return (
                <img
                  src="https://www.pngitem.com/pimgs/m/101-1014144_computer-icons-rectangle-circle-video-play-button-transparent.png"
                  width="60px"
                  style={{ marginRight: "8px" }}
                />
              );
            }
          }}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <ImageField
            classes={classes}
            source="thumbnailUrl"
            label="Thumbnail"
            sortable={false}
          />
          <TextField source="videoTitle" label="Title" />
          <TextField source="videoSubtitle" label="Subtitle" />
          <ReferenceArrayField
            label="Categories"
            source="categoryIds"
            reference="categories"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="categoryName"></ChipField>
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label="Inherited Perms"
            source="inheritedPGIds"
            reference="permission-groups"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="permissionGroupName" size="small"></ChipField>
            </SingleFieldList>
          </ReferenceArrayField>
          <FunctionField
            label="Duration"
            source="videoLength"
            sortable={false}
            render={(record) =>
              record.videoLength
                ? new Date(record.videoLength * 1000)
                    .toISOString()
                    .substr(11, 8)
                : null
            }
          />
          {/* <FunctionField
            label="Quality"
            source="videoDetails"
            render={(record) => Object.keys((record.videoDetails)).toString()}
          /> */}
          <TextField source="isAvailable" label="Available" />
          <TextField source="views" label="Views" />
          <FunctionField
            label="Created"
            source="createdDate"
            render={(record) => timestampFormat(record.createdDate)}
          />
          <FunctionField
            label="Updated"
            source="updatedDate"
            render={(record) => timestampFormat(record.updatedDate)}
          />
        </Datagrid>
      )}
    </List>
  );
});
