import React from 'react';
import {
  withStyles,
  Paper,
  List,
  ListItem,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  ListItemAvatar,
} from '@material-ui/core';
import { compose } from 'recompose';
import red from '@material-ui/core/colors/red';
import ActionPlanIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';

import comparator from '../../utils/comparator';

const ActionPlanPanel = (props) => {
  const actionPlan = [];
  const {
    classes, compact, empty, lastData, threshold, operator, ruleName,
  } = props;

  const listItemClass = classnames(classes.listItem, compact && classes.padding);
  let content;
  if (!lastData) {
    return (
      <Skeleton height="100%" />
    );
  } if (comparator(lastData.percent, threshold, operator)) {
    actionPlan.push({ text: lastData.fluidState === 'New' ? 'Low Level: ' : 'High Level: ', value: ruleName });
    actionPlan.push({ text: 'Call Distributor ', value: '' });
    if (empty) {
      actionPlan.push({
        text: lastData.fluidState === 'New' ? 'Estimated Outage Days: ' : 'Estimated Days Until Full: ',
        value: `${Math.round(empty)} days`,
      });
    }

    content = actionPlan.map((e, index) => (
      <ListItem className={listItemClass} key={index}>
        <ListItemAvatar>
          <Avatar className={classes.redAvatar}>
            <ActionPlanIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={e.text}
          className={compact ? classes.listItemTextPadding : undefined}
          primaryTypographyProps={compact ? { variant: 'body1' } : null}
          secondaryTypographyProps={{ variant: 'caption' }}
        />

        <ListItemSecondaryAction>
          <Button disabled style={{ color: '#f44336', textTransform: 'none' }}>
            {`${e.value}`}
          </Button>
        </ListItemSecondaryAction>

      </ListItem>
    ));
  } else {
    content = <Typography className={classes.title}>No Action Plan To Show</Typography>;
  }


  return (
    <Paper className={compact ? classes.compactRoot : classes.root}>
      <List>
        <Typography variant="h6" className={classes.title}>
          Action Plan
        </Typography>
        {content}
      </List>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    height: theme.spacing(40),
    overflow: 'auto',
  },
  compactRoot: {
    width: '100%',
    height: 270,
    overflow: 'auto',
  },
  redAvatar: {
    color: red[500],
    backgroundColor: '#ffffff',
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemTextPadding: {
    paddingLeft: 0,
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
});

ActionPlanPanel.defaultProps = {
  compact: false,
};

ActionPlanPanel.propTypes = {
  compact: PropTypes.bool,
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(ActionPlanPanel);
