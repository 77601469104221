/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-return-assign */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import {
  withStyles,
  DialogActions,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { DateRange as DateIcon, ArrowDropDown } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Link from './Link';
import * as actions from '../../../store/actions';
import { TimezoneContext } from '../../../contexts';

const localTimezone = moment.tz.guess(true);

const TimeFilter = (props) => {
  const { classes, fullScreen } = props;

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const startDate = React.createRef();
  const endDate = React.createRef();
  const ranges = useSelector(state => state.timeRangeData.ranges);
  const selectedTimeRange = useSelector(state => state.timeRangeData.selectedTimeRange);
  const selectedLocation = useSelector(state => state.locationData.selectedLocation);
  const { timezone, setTimezone } = React.useContext(TimezoneContext);
  const [open, setOpen] = React.useState(false);
  const [start, setStart] = React.useState(moment(selectedTimeRange.start(timezone)).toDate());
  const [end, setEnd] = React.useState(moment(selectedTimeRange.end(timezone)).toDate());
  const dispatch = useDispatch();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDateChange = (e, range) => {
    e.preventDefault();
    dispatch({ type: actions.SET_SELECTED_TIME_RANGE, value: range });
    setOpen(false);
  };

  const onChangeStart = (e) => {
    console.log('onChangeStart');
    setStart(e.target.value);
  };

  const onChangeEnd = (e) => {
    console.log('onChangeEnd');
    setEnd(e.target.value);
  };

  const applyRange = (e) => {
    e.preventDefault();
    const label = ` ${moment(start).format('DD-MMM-YYYY HH:mm')} - ${moment(end).format(
      'DD-MMM-YYYY HH:mm',
    )} `;
    if (moment(start).unix() <= moment(end).unix()) {
      handleDateChange(e, {
        start: () => moment(start).unix(),
        end: () => moment(end).unix(),
        label,
      });
    } else {
      toggleOpen();
      showNotification('Error: Start Date Greater Than End Date', 'warning');
    }
  };

  const onTimezoneChange = (event, tz) => {
    if (tz) {
      setTimezone(tz);
    }
  };

  return (
    <Grid item container alignItems="center" spacing={2}>
      {selectedLocation && (
      <Grid item>
        <ToggleButtonGroup
          exclusive
          onChange={onTimezoneChange}
          value={timezone}
          size="small"
        >
          <ToggleButton value={localTimezone} size="small">
            <Typography variant="button">
              Local
            </Typography>
          </ToggleButton>
          <ToggleButton value={selectedLocation.timezone} size="small">
            <Typography variant="button">
              Location
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      )}
      <Grid item>
        <Button
          fullWidth={isSmall}
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={toggleOpen}
        >
          <DateIcon className={classes.leftIcon} />
          {selectedTimeRange ? selectedTimeRange.label : ''}
          <ArrowDropDown />
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">Quick Ranges</DialogTitle>
        <DialogContent>
          <Grid container spacing={16}>
            {ranges.map((range, i) => (
              <Grid item xs={6} sm={3} key={i}>
                {Object.keys(range).map(key => (
                  <Grid item xs={12} key={key}>
                    <Link
                      className={classes.padding}
                      text={key}
                      onClick={e => handleDateChange(e, { label: key, ...range[key] })}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
          <br />
          <TextField
            name="start"
            label="Start Time"
            type="datetime-local"
            fullWidth
            defaultValue={start}
            className={classes.textField}
            onChange={onChangeStart}
            inputRef={startDate}
            InputLabelProps={{ shrink: true }}
          />
          <br />
          <br />
          <TextField
            name="end"
            label="End Time"
            type="datetime-local"
            fullWidth
            defaultValue={end}
            className={classes.typography}
            onChange={onChangeEnd}
            inputRef={endDate}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button href="/" color="primary" onClick={applyRange}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

TimeFilter.propTypes = {};

const styles = theme => ({
  typography: { fontSize: 10 },
  padding: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: 0,
    paddingLeft: 0,
  },
  leftIcon: { marginRight: theme.spacing(1) },
  selected: { backgroundColor: theme.palette.primary.main },
});

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(TimeFilter);
