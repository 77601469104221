import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createAdminStore from './store/createAdminStore';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import history from './utils/history';

require('dotenv').config();

const app = (
  <Provider store={createAdminStore({ authProvider, dataProvider, history })}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
