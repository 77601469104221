import React from 'react';
import { connect } from 'react-redux';
import { userLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import ChangePasswordIcon from '@material-ui/icons/PowerSettingsNew';
import LogoutIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';

import history from '../utils/history';

const LogoutButton = ({
  // eslint-disable-next-line no-shadow
  userLogout,
  classes,
}) => (
  <div className={classes.headerMenu}>
    <MenuItem>
      <div className={classes.profileMenuUser}>
        <Typography variant="h5" weight="bold">
          {localStorage.getItem('name')}
        </Typography>
      </div>
    </MenuItem>

    <MenuItem
      onClick={() => {
        history.push('/change-password');
      }}
    >
      <LogoutIcon style={{ marginRight: 8 }} />
      Change Password
    </MenuItem>

    <MenuItem
      onClick={() => {
        history.push('/settings');
      }}
    >
      <SettingsIcon style={{ marginRight: 8 }} />
      Settings
    </MenuItem>

    <MenuItem onClick={userLogout}>
      <ChangePasswordIcon style={{ marginRight: 8 }} />
      Sign Out
    </MenuItem>
  </div>
);

const styles = theme => ({
  logotype: {
    color: 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 300,
    fontSize: 14,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  appBar: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  normal_text: {
    decoration: 'none',
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: '100%',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: 'rgba(255, 255, 255, 0.35)',
  },
  headerIconCollapse: {
    color: 'white',
  },
  marginProfile: {
    marginRight: 1,
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
    textdecoration: 'none',
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    normalText: {
      textDecorationColor: 'RED',
    },
  },
});

const withConnect = connect(
  undefined,
  { userLogout: () => userLogout() },
);

export default compose(
  withStyles(styles),
  withConnect,
)(LogoutButton);
