const thresholdColor = (fluidState, value) => {
  if (fluidState === 'New') {
    if (value <= 25) {
      return '#d34a3a';
    }

    return '#289d45';
  }

  if (value >= 75) {
    return '#d34a3a';
  }

  return '#289d45';
};
export default thresholdColor;
