import moment from 'moment-timezone';

const ranges = [
  {
    'Last 2 days': {
      start: tz => moment.tz(tz)
        .subtract(2, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 7 days': {
      start: tz => moment.tz(tz)
        .subtract(7, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 30 days': {
      start: tz => moment.tz(tz)
        .subtract(30, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 90 days': {
      start: tz => moment.tz(tz)
        .subtract(90, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 6 months': {
      start: tz => moment.tz(tz)
        .subtract(6, 'months')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 1 year': {
      start: tz => moment.tz(tz)
        .subtract(1, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 2 years': {
      start: tz => moment.tz(tz)
        .subtract(2, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 5 years': {
      start: tz => moment.tz(tz)
        .subtract(5, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Only Yesterday': {
      start: tz => moment.tz(tz)
        .subtract(1, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'days')
        .endOf('day')
        .unix(),
    },
    'Day before yesterday': {
      start: tz => moment.tz(tz)
        .subtract(2, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(2, 'days')
        .endOf('day')
        .unix(),
    },
    'This day last week': {
      start: tz => moment.tz(tz)
        .subtract(7, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(7, 'days')
        .endOf('day')
        .unix(),
    },
    'Previous week': {
      start: tz => moment.tz(tz)
        .subtract(1, 'weeks')
        .startOf('weeks')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'weeks')
        .endOf('weeks')
        .unix(),
    },
    'Previous month': {
      start: tz => moment.tz(tz)
        .subtract(1, 'months')
        .startOf('months')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'months')
        .endOf('months')
        .unix(),
    },
    'Previous year': {
      start: tz => moment.tz(tz)
        .subtract(1, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'years')
        .endOf('years')
        .unix(),
    },
    'Previous 2 years': {
      start: tz => moment.tz(tz)
        .subtract(2, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(2, 'years')
        .endOf('years')
        .unix(),
    },
    'Previous 5 years': {
      start: tz => moment.tz(tz)
        .subtract(5, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(5, 'years')
        .endOf('years')
        .unix(),
    },
  },
  {
    'Only Today': {
      start: tz => moment.tz(tz)
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Today so far': {
      start: tz => moment.tz(tz)
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This week': {
      start: tz => moment.tz(tz)
        .startOf('week')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This week so far': {
      start: tz => moment.tz(tz)
        .startOf('week')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This month': {
      start: tz => moment.tz(tz)
        .startOf('month')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This month so far': {
      start: tz => moment.tz(tz)
        .startOf('month')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This year': {
      start: tz => moment.tz(tz)
        .startOf('year')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This year so far': {
      start: tz => moment.tz(tz)
        .startOf('year')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 5 minutes': {
      start: tz => moment.tz(tz)
        .subtract(5, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 15 minutes': {
      start: tz => moment.tz(tz)
        .subtract(15, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 30 minutes': {
      start: tz => moment.tz(tz)
        .subtract(30, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 1 hour': {
      start: tz => moment.tz(tz)
        .subtract(1, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 3 hours': {
      start: tz => moment.tz(tz)
        .subtract(3, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 6 hour': {
      start: tz => moment.tz(tz)
        .subtract(6, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 12 hour': {
      start: tz => moment.tz(tz)
        .subtract(12, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 24 hour': {
      start: tz => moment.tz(tz)
        .subtract(24, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
];

export const rangesTx = [
  {
    'Last 2 days': {
      start: tz => moment.tz(tz)
        .subtract(2, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Only Yesterday': {
      start: tz => moment.tz(tz)
        .subtract(1, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'days')
        .endOf('day')
        .unix(),
    },
    'Only Today': {
      start: tz => moment.tz(tz)
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 5 minutes': {
      start: tz => moment.tz(tz)
        .subtract(5, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 7 days': {
      start: tz => moment.tz(tz)
        .subtract(7, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Day before yesterday': {
      start: tz => moment.tz(tz)
        .subtract(2, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(2, 'days')
        .endOf('day')
        .unix(),
    },
    'Today so far': {
      start: tz => moment.tz(tz)
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 15 minutes': {
      start: tz => moment.tz(tz)
        .subtract(15, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 30 days': {
      start: tz => moment.tz(tz)
        .subtract(30, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'This day last week': {
      start: tz => moment.tz(tz)
        .subtract(7, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(7, 'days')
        .endOf('day')
        .unix(),
    },
    'This week': {
      start: tz => moment.tz(tz)
        .startOf('week')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 30 minutes': {
      start: tz => moment.tz(tz)
        .subtract(30, 'minutes')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 90 days': {
      start: tz => moment.tz(tz)
        .subtract(90, 'days')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Previous week': {
      start: tz => moment.tz(tz)
        .subtract(1, 'weeks')
        .startOf('weeks')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'weeks')
        .endOf('weeks')
        .unix(),
    },
    'This week so far': {
      start: tz => moment.tz(tz)
        .startOf('week')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 1 hour': {
      start: tz => moment.tz(tz)
        .subtract(1, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 6 months': {
      start: tz => moment.tz(tz)
        .subtract(6, 'months')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Previous month': {
      start: tz => moment.tz(tz)
        .subtract(1, 'months')
        .startOf('months')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'months')
        .endOf('months')
        .unix(),
    },
    'This month': {
      start: tz => moment.tz(tz)
        .startOf('month')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 3 hours': {
      start: tz => moment.tz(tz)
        .subtract(3, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 1 year': {
      start: tz => moment.tz(tz)
        .subtract(1, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Previous year': {
      start: tz => moment.tz(tz)
        .subtract(1, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'years')
        .endOf('years')
        .unix(),
    },
    'This month so far': {
      start: tz => moment.tz(tz)
        .startOf('month')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 6 hour': {
      start: tz => moment.tz(tz)
        .subtract(6, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 2 years': {
      start: tz => moment.tz(tz)
        .subtract(2, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Previous 2 years': {
      start: tz => moment.tz(tz)
        .subtract(2, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(2, 'years')
        .endOf('years')
        .unix(),
    },
    'This year': {
      start: tz => moment.tz(tz)
        .startOf('year')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 12 hour': {
      start: tz => moment.tz(tz)
        .subtract(12, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
  {
    'Last 5 years': {
      start: tz => moment.tz(tz)
        .subtract(5, 'years')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Previous 5 years': {
      start: tz => moment.tz(tz)
        .subtract(5, 'years')
        .startOf('years')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(5, 'years')
        .endOf('years')
        .unix(),
    },
    'This year so far': {
      start: tz => moment.tz(tz)
        .startOf('year')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
    'Last 24 hour': {
      start: tz => moment.tz(tz)
        .subtract(24, 'hours')
        .unix(),
      end: tz => moment.tz(tz).unix(),
    },
  },
];

export default ranges;
