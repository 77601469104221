import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DocTypeIcon, DocTypeIconByRecord, OuterDiv } from "./CustomComponent";
import timestampFormat from "../components/DateFormat";

import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  ExportButton,
  useRedirect,
  FunctionField,
  ReferenceField,
  SingleFieldList,
  SimpleList,
  ReferenceArrayField,
  ChipField,
} from "react-admin";
import { Box, Grid, Button, Typography, Chip } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HomeIcon from "@material-ui/icons/Home";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import "./Docs.css";
import dataProvider from "../../dataProvider";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default ({ permissions, ...props }) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [currFolderId, setCurrFolderId] = useState(
    localStorage.getItem("currFolderId") || "root"
  );
  const [currFolderName, setCurrFolderName] = useState(
    localStorage.getItem("currFolderName") || "root"
  );
  const [currFolderParentId, setCurrFolderParentId] = useState(
    localStorage.getItem("currFolderParentId") || "root"
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const redirect = useRedirect();
  localStorage.setItem("parentId", currFolderId);

  useEffect(() => {
    setDocParentDetails();
  }, [currFolderId]);

  const setDocParentDetails = async () => {
    if (currFolderId !== "root") {
      if (currFolderId === currFolderParentId) {
        const folderArr = currFolderName.split("/");
        const lastFolderName = `/${folderArr[folderArr.length - 1]}`;
        setCurrFolderName(currFolderName.replace(lastFolderName, ""));
      }
      console.log("currFolderId: ", currFolderId);
      const res = await dataProvider.getOne("docs", { id: currFolderId });
      console.log("Resp: ", res);
      localStorage.setItem(
        "inheritedPGIds",
        JSON.stringify([
          ...new Set([
            ...(res.data.inheritedPGIds || []),
            ...(res.data.allowedPGIds || []),
          ]),
        ])
      );
      setCurrFolderParentId(res.data.parentId);
    } else {
      setCurrFolderParentId("root");
      setCurrFolderName("root");
    }
    localStorage.setItem("currFolderId", currFolderId);
    localStorage.setItem("currFolderParentId", currFolderParentId);
    localStorage.setItem("currFolderName", currFolderName);
  };

  // style={{ marginBottom: '-50px' }}
  //style={{ marginBottom: '-72px', zIndex: '2' }}
  const ListActions = () => (
    <Box width="100%">
      <TopToolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <div style={{ wordWrap: "break-word" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<HomeIcon />}
              onClick={() => setCurrFolderId("root")}
            >
              Home
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ArrowBackIosIcon />}
              style={{ marginLeft: "10px" }}
              onClick={async () => {
                setCurrFolderId(currFolderParentId);
              }}
            >
              Back
            </Button>
          </div>
          <Typography variant="h5" noWrap="false">
            {currFolderName}
          </Typography>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              style={{ marginLeft: "10px" }}
              onClick={() => redirect("create", "/docs")}
            >
              Create
            </Button>
            {/* <ExportButton variant="contained" style={{ marginLeft: "10px" }} /> */}
          </div>
        </Grid>
      </TopToolbar>
    </Box>
  );

  const postRowStyle = (record, index) => ({
    textDecoration: record.status === -1 ? "line-through" : "none",
  });

  return (
    <div>
      <ListActions />
      <Snackbar
        style={{ marginTop: "100px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message="Coming soon"
        autoHideDuration={6000}
        key={"topcenter"}
      />
      <List
        className="list-style"
        // actions={<ListActions />}
        filter={{ parentId: currFolderId }}
        {...props}
        hasCreate={false}
        exporter={false}
        sort={{field: "updatedDate",order: "-1"}}
      >
        {isSmall ? (
          <SimpleList
            leftIcon={(record) => (
              <DocTypeIconByRecord record={record} label="Type" />
            )}
            // leftAvatar={}
            primaryText={(record) => record.name}
            secondaryText={(record) => record.id}
            tertiaryText={(record) => (
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<KeyboardArrowRightIcon />}
                  onClick={() => {
                    if (record.type === "dir") {
                      setCurrFolderId(record._id);
                      setCurrFolderName(`${currFolderName}/${record.name}`);
                    } else {
                      setOpenSnackbar(true);
                    }
                  }}
                >
                  {record.type === "dir" ? "Open" : "Download"}
                </Button>
              </div>
            )}
          />
        ) : (
          <Datagrid
            rowStyle={postRowStyle}
            // style={{ wordWrap: 'break-word', width: '100%' }}
            style={{ color: "gray" }}
            
          >
            <FunctionField
              render={(record) => (
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    endIcon={<KeyboardArrowRightIcon />}
                    onClick={() => {
                      if (record.type === "dir") {
                        setCurrFolderId(record.id);
                        setCurrFolderName(`${currFolderName}/${record.name}`);
                      } else {
                        setOpenSnackbar(true);
                      }
                    }}
                  >
                    {record.type === "dir" ? "Open" : "Download"}
                  </Button>
                </div>
              )}
            />
            <FunctionField
              label="Name"
              render={(record) => (
                <Grid
                  item
                  container
                  alignItems="center"
                  wrap="nowrap"
                  // style={{ color: 'gray' }}
                >
                  <Grid item style={{ width: "40px" }}>
                    <DocTypeIcon label="Type" />
                  </Grid>
                  <Grid item sm>
                    <TextField source="name" label="Name" />
                  </Grid>
                </Grid>
              )}
              fullWidth
            />
            {/* <TextField source="desc" label="Desc" /> */}
            {/* <ReferenceArrayField
              label="InheritedPermissionGroups"
              source="inheritedPGIds"
              reference="permission-groups"
              sortable={false}
            >
              <SingleFieldList>
                <ChipField source="permissionGroupName" size="small" />
              </SingleFieldList>
            </ReferenceArrayField> */}
            <ReferenceArrayField
              label="SelfPermissions"
              source="allowedPGIds"
              reference="permission-groups"
              sortable={false}
            >
              <SingleFieldList>
                <ChipField source="permissionGroupName" size="small" />
              </SingleFieldList>
            </ReferenceArrayField>
            {/* <ReferenceArrayField
              label="TraversalPermissionGroups"
              source="traversalPGIds"
              reference="permission-groups"
              sortable={false}
            >
              <SingleFieldList>
                <ChipField source="permissionGroupName" size="small" />
              </SingleFieldList>
            </ReferenceArrayField> */}
            <ReferenceArrayField
              label="ResultantPermissions"
              source="resultantPGIds"
              reference="permission-groups"
              sortable={false}
            >
              <SingleFieldList>
                <ChipField source="permissionGroupName" size="small" />
              </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="id" label="Document Id" />
            {/* <TextField source="gDriveFileId" label="GoogleDrive Id" /> */}
            <FunctionField
              render={(record) => (
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    endIcon={<EditIcon />}
                    onClick={() => redirect("edit", "/docs", record.id)}
                  >
                    Edit
                  </Button>
                </div>
              )}
            />
            <FunctionField
              label="Created"
              source="createdDate"
              render={(record) => timestampFormat(record.createdDate)}
            />
            <FunctionField
              label="Updated"
              source="updatedDate"
              render={(record) => timestampFormat(record.updatedDate)}
            />
          </Datagrid>
        )}
      </List>
    </div>
  );
};
