import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
// import { withStyles } from '@material-ui/core';
// import Circle from './Circle';

// const LoadingIndicator = ({ classes }) => (
//   <div className={classes.wrapper}>
//     <Circle />
//     <Circle rotate={30} delay={-1.1} />
//     <Circle rotate={60} delay={-1} />
//     <Circle rotate={90} delay={-0.9} />
//     <Circle rotate={120} delay={-0.8} />
//     <Circle rotate={150} delay={-0.7} />
//     <Circle rotate={180} delay={-0.6} />
//     <Circle rotate={210} delay={-0.5} />
//     <Circle rotate={240} delay={-0.4} />
//     <Circle rotate={270} delay={-0.3} />
//     <Circle rotate={300} delay={-0.2} />
//     <Circle rotate={330} delay={-0.1} />
//   </div>
// );

// const styles = () => ({
//   wrapper: {
//     margin: '1em auto',
//     width: 40,
//     height: 40,
//     // maxWidth: 60,
//     // maxHeight: 60,
//     position: 'relative',
//   },
// });

// export default withStyles(styles)(LoadingIndicator);

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '1em auto',
    width: 40,
    height: 40,
    // maxWidth: 60,
    // maxHeight: 60,
    position: 'relative',
  },
}));

const LoadingIndicator = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.wrapper}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
export default LoadingIndicator;
