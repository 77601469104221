/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Map,
  data as Data,
  source as Source,
  layer as Layer,
  Popup,
  Shape,
} from 'azure-maps-control';

import { Title, useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import { useMediaQuery, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

import { DistributorFilter, TimeFilter } from './filters';
import LoadingIndicator from '../components/LoadingIndicator';
import DistributorLocationList from './DistributorLocationList';
import { TimezoneContext } from '../contexts';

const DistributorDashboard = (props) => {
  const popup = React.useRef();

  const { classes } = props;

  const dataProvider = useDataProvider();
  const views = useSelector(state => state.admin.ui.viewVersion);
  const selectedDistributor = useSelector(state => state.distributorData.selectedDistributor);
  const selectedTimeRange = useSelector(state => state.timeRangeData.selectedTimeRange);
  const [open, setOpen] = React.useState(false);
  const [lastData, setLastData] = React.useState();
  const { timezone } = React.useContext(TimezoneContext);

  React.useEffect(() => {
    async function fetchLastData(distributorId, fromTime, toTime) {
      const response = await dataProvider.getAll('distributor/last', {
        filter: { distributorId, fromTime, toTime },
      }).then(({ data }) => data);
      setLastData(response);
    }

    if (selectedDistributor && selectedTimeRange) {
      setLastData(undefined);
      const start = moment(selectedTimeRange.start(timezone) * 1000).toISOString();
      const end = moment(selectedTimeRange.end(timezone) * 1000).toISOString();
      fetchLastData(selectedDistributor.id, start, end);
    }
  }, [selectedDistributor, selectedTimeRange, dataProvider, views, timezone]);

  function clusterClicked(e) {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      const cluster = e.shapes[0];
      datasource.getClusterExpansionZoom(cluster.properties.cluster_id).then((zoom) => {
        map.setCamera({
          center: cluster.geometry.coordinates,
          zoom,
          type: 'ease',
          duration: 200,
        });
      });
    }
  }

  function closePopup() {
    popup.current.close();
  }

  function object2Table(obj) {
    const html = ['<table><tr><td><b>', obj.locationName, '</b></td></tr>'];
    html.push('<table><tr><td><b>Tanks</b></td><td><b>Percent</b></td><tr>');
    obj.tanks.forEach((tank) => {
      if (typeof tank === 'object') {
        html.push('<tr><td>', tank.tank, '</td>', "<td align='center'>", tank.percent, '</td>');
      }
    });
    html.push('</table>');
    return html.join('');
  }

  function openLocationDialog() {
    setOpen(!open);
  }

  React.useEffect(() => {
    let map = null;

    function getTanks() {
      const layerData = [];
      (lastData || []).forEach((each) => {
        layerData.push(
          new Data.Feature(new Data.Point([each.address.lon, each.address.lat]), {
            tanksOnAlert: each.tank_count,
            locationName: each.name,
            lowestTankFluidState: each.lowestTankFluidState,
            lowestTankLevel: each.lowestTankLevel,
            tanks: each.tanks,
          }),
        );
      });

      return layerData;
    }

    function symbolHovered(e) {
      if (e.shapes && e.shapes.length > 0) {
        let pos = e.position;
        let offset = [0, 0];
        let properties;
        if (e.shapes[0] instanceof Shape) {
          properties = e.shapes[0].getProperties();
          if (e.shapes[0].getType() === 'Point') {
            pos = e.shapes[0].getCoordinates();
            offset = [0, -18];
          }
        } else {
          // eslint-disable-next-line prefer-destructuring
          properties = e.shapes[0].properties;
          if (e.shapes[0].type === 'Point') {
            pos = e.shapes[0].geometry.coordinates;
            offset = [0, -18];
          }
        }
        popup.current.setOptions({
          content: `<div style="padding:10px;max-height:200px;position:absolute;background-color:white;overflow-y:auto;box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12);">${object2Table(
            properties,
          )}</div>`,
          position: pos,
          pixelOffset: [230, -525],
        });
        popup.current.open(map);
      }
    }

    function getMap() {
      let datasource;
      let defaultOptions;

      const features = getTanks();
      const bounds = Data.BoundingBox.fromData(new Data.FeatureCollection(features));
      map = new Map('myMap', {
        bounds,
        center: [-122.33, 47.63],
        padding: {
          top: 100,
          bottom: 100,
          left: 100,
          right: 100,
        },
        authOptions: {
          authType: 'subscriptionKey',
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY,
        },
      });

      map.events.add('ready', () => {
        datasource = new Source.DataSource();
        map.sources.add(datasource);
        datasource.add(getTanks());
        const clusterBubbleLayer = new Layer.BubbleLayer(datasource, 'earthquake-circles', {
          color: [
            'match',
            ['get', 'lowestTankFluidState'],
            'New',
            ['step', ['get', 'lowestTankLevel'], '#d34a3a', 25, '#289d45', 50, '#289d45'],
            ['step', ['get', 'lowestTankLevel'], '#289d45', 25, '#289d45', 75, '#d34a3a'],
          ],
          radius: 20,
        });

        const symbolLayer = new Layer.SymbolLayer(datasource, 'earthquake-labels', {
          iconOptions: {
            image: 'none',
          },
          textOptions: {
            textField: ['concat', ['to-string', ['get', 'tanksOnAlert']], ''],
            color: 'white',
          },
        });
        map.layers.add([
          clusterBubbleLayer,
          symbolLayer,
        ]);

        popup.current = new Popup({
          position: [0, 0],
          pixelOffset: [0, -18],
        });

        map.events.add('mouseover', symbolLayer, symbolHovered);
        map.events.add('touchstart', symbolLayer, symbolHovered);
        map.events.add('mouseout', symbolLayer, closePopup);
        map.events.add('touchend', closePopup);

        map.resize();
      });
    }

    if (lastData && !map) {
      getMap();
    }
  }, [lastData]);

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box m={1}>
      <Title title="Distributor" />
      <Box mt={1}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={isSmall && 12}>
            <DistributorFilter />
          </Grid>
          <Grid item xs={isSmall && 12}>
            <TimeFilter />
          </Grid>
        </Grid>
      </Box>

      {isSmall ? <div /> : <br />}
      <Grid item container spacing={2}>
        {isSmall ? (
          <Hidden xsUp={!lastData}>
            <Grid item xs={12}>
              <Paper className={classes.mapMobContainer} square>
                <div id="myMap" className={classes.mapMob} />
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="list"
                  className={classes.listIcon}
                  onClick={openLocationDialog}
                >
                  <ListIcon />
                </Button>

                <Dialog
                  open={open}
                  onClose={openLocationDialog}
                  style={{ height: '60%' }}
                  maxWidth="xs"
                  fullWidth
                  fullScreen
                >
                  <DialogTitle id="dialog">
                    <Typography variant="h6">Select a location</Typography>
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={openLocationDialog}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <Divider />
                  <DistributorLocationList data={lastData} />
                </Dialog>
              </Paper>
            </Grid>
          </Hidden>
        ) : (
          <>
            <Grid item className={classes.root} xs={3}>
              {lastData
                ? <DistributorLocationList data={lastData} />
                : (
                  <Grid container spacing={2} direction="column">
                    {[...Array(3).keys()].map(v => (
                      <Grid item key={v}>
                        <Skeleton height={180} />
                      </Grid>
                    ))}
                  </Grid>
                )}
            </Grid>
            <Grid item xs={9}>
              {lastData ? (
                <Paper className={classes.mapContainer} square>
                  <div id="myMap" className={classes.map} />
                </Paper>
              ) : (
                <div style={{ height: 'calc(100vh - 160px)' }}>
                  <Skeleton height="100%" />
                </div>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const styles = theme => ({
  root: { maxHeight: 'calc(100vh - 112px)', overflowY: 'scroll' },
  listIcon: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(1),
  },
  list: { maxHeight: theme.spacing(15), overflow: 'auto' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  mapContainer: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'calc(100vh - 160px)',
  },
  mapMobContainer: { height: 'calc(100vh - 128px)' },
  map: { width: '100%', position: 'relative', height: 'calc(100vh - 192px)' },
  mapMob: { width: '100%', height: 'calc(100vh - 128px)' },
});

export default compose(
  withStyles(styles, { withTheme: true }),
)(DistributorDashboard);
