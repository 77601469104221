import moment from 'moment-timezone';
import * as actions from './actions';
import ranges from '../dashboard/filters/TimeFilter/ranges';

export const distributorReducer = (state = {
  distributors: [],
  selectedDistributor: null,
}, action) => {
  switch (action.type) {
    case actions.SET_DISTRIBUTOR_DATA:
      return action.value;
    case actions.SET_SELECTED_DISTRIBUTOR:
      return { ...state, selectedDistributor: action.value };
    default:
      return state;
  }
};

export const locationReducer = (state = {
  locations: [],
  selectedLocation: null,
}, action) => {
  switch (action.type) {
    case actions.SET_LOCATION_DATA:
      return action.value;
    case actions.SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.value };
    default:
      return state;
  }
};

export const tankReducer = (state = {
  tanks: [],
  selectedTank: null,
}, action) => {
  switch (action.type) {
    case actions.SET_TANK_DATA:
      return action.value;
    case actions.SET_SELECTED_TANK:
      return { ...state, selectedTank: action.value };
    default:
      return state;
  }
};

export const timeRangeReducer = (state = {
  ranges,
  selectedTimeRange: {
    label: 'Last 2 days',
    start: () => moment()
      .subtract(2, 'days')
      .unix(),
    end: () => moment().unix(),
  },
}, action) => {
  switch (action.type) {
    case actions.SET_TIME_RANGE_DATA:
      return action.value;
    case actions.SET_SELECTED_TIME_RANGE:
      return { ...state, selectedTimeRange: action.value };
    default:
      return state;
  }
};
