import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import thresholdColor from '../utils/threshold';
import history from '../utils/history';
import * as actions from '../store/actions';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      zIndex: -2,
    },
  },
}));

const DistributorLocationListItem = (props) => {
  const {
    name,
    address: { name: address },
    tank_alert: tanksOnAlert,
    tank_count: numberOfTanks,
    lowestTankLevel,
    lowestTankFluidState,
    id,
    estimatedOutage,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector(state => state.locationData.locations);
  const backgroundColor = thresholdColor(lowestTankFluidState, lowestTankLevel);

  function cardClick() {
    const location = locations.find(e => e.id === id);
    dispatch({
      type: actions.SET_SELECTED_LOCATION,
      value: {
        id: location.id,
        desc: `${location.name}, ${location.address.name}`,
        timezone: location.timezone,
      },
    });
    history.push('/dashboard/location');
  }

  return (
    <Card
      style={{ cursor: 'pointer' }}
      onClick={() => cardClick(id)}
      className={classes.card}
      key={id}
    >
      <CardContent>
        <Typography variant="h5">{name}</Typography>
        <Typography variant="body1" color="textSecondary">
          {address}
        </Typography>
      </CardContent>
      <CardContent style={{ backgroundColor }}>
        {tanksOnAlert > 0 && (
          <Typography variant="body1" style={{ color: 'white' }}>
            {`${tanksOnAlert} Tanks low`}
          </Typography>
        )}
        {tanksOnAlert > 0 && (
          <Typography variant="body1" style={{ color: 'white' }}>
            {`Estimated first outage ${estimatedOutage}`}
          </Typography>
        )}

        <Typography variant="body1" style={{ color: 'white' }}>
          {`${numberOfTanks} Tanks on site`}
        </Typography>
      </CardContent>
    </Card>
  );
};

const DistributorLocationList = (props) => {
  const { data } = props;
  return (data || []).map(location => (
    <DistributorLocationListItem key={location.id} {...location} />
  ));
};

export default DistributorLocationList;
