import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const NoDataToShow = ({ classes, compact }) => (
  <div className={compact ? classes.compactWrapper : classes.wrapper}>
    <Typography className={classes.title}>No data to display</Typography>
  </div>
);

const styles = () => ({
  wrapper: {
    margin: '1em auto',
    minWidth: 40,
    minHeight: 40,
    // maxWidth: 60,
    // maxHeight: 60,
    position: 'relative',
  },
  compactWrapper: {
    margin: '1em auto',
    minWidth: 20,
    minHeight: 20,
    position: 'relative',
  },
});

export default withStyles(styles)(NoDataToShow);
