import React from 'react';
import { ArrowDropDown, Check, LocalShipping as DistributorIcon } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { withStyles, withMobileDialog, useMediaQuery } from '@material-ui/core';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions';

const DistributorFilter = (props) => {
  const { classes, fullScreen } = props;

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const distributors = useSelector(state => state.distributorData.distributors);
  const selectedDistributor = useSelector(state => state.distributorData.selectedDistributor);
  const [open, setOpen] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleDistributorSelect = (selected) => {
    dispatch({
      type: actions.SET_SELECTED_DISTRIBUTOR,
      value: selected,
    });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        fullWidth={isSmall}
        variant="contained"
        color="primary"
        aria-labelledby="dialog"
        onClick={selectedDistributor ? openDialog : null}
      >
        <DistributorIcon className={classes.icon} />
        {selectedDistributor ? `${selectedDistributor.name}` : 'Fetching distributors...'}
        <ArrowDropDown />
      </Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="xs" fullWidth fullScreen={fullScreen}>
        <DialogTitle id="dialog">Select a distributor</DialogTitle>
        <Divider />
        <List className={classes.list}>
          {distributors.map(distributor => (
            <ListItem
              button
              key={distributor.id}
              onClick={() => handleDistributorSelect(distributor)}
            >
              <ListItemAvatar>
                {selectedDistributor.id === distributor.id ? (
                  <Avatar className={classes.selected}>
                    <Check />
                  </Avatar>
                ) : (
                  <Avatar>
                    <DistributorIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={`${distributor.name}`} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </React.Fragment>
  );
};

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    maxHeight: theme.spacing(30),
    overflow: 'visible',
  },
});

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(DistributorFilter);
