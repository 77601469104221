import React from 'react';
import { Title } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

const NoAccess = props => (
  <Card>
    <Title title={props.message} />
    <CardContent>
      <h1>{props.message}</h1>
    </CardContent>
  </Card>
);

export default NoAccess;
