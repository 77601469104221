import React from 'react';

export default class Link extends React.Component {
  state = { hover: false };

  toggleHover = () => {
    this.setState(prevState => ({ hover: !prevState.hover }));
  };

  render() {
    const { hover } = this.state;
    const { text, onClick } = this.props;
    const linkStyle = {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 12,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    };
    return (
      <label
        htmlFor="as"
        style={
          hover
            ? linkStyle
            : { fontSize: 12, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }
        }
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={onClick}
      >
        {text}
      </label>
    );
  }
}
