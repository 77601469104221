const comparator = (value, threshold, operator) => {
  switch (operator) {
    case '<=':
      return value <= threshold;
    case '>=':
      return value >= threshold;
    case '<':
      return value < threshold;
    case '>':
      return value > threshold;
    default:
      return false;
  }
};

export default comparator;
