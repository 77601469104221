import React from 'react';
import {
  Button,
  Dialog,
  Modal,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
} from '@material-ui/core';
import { withDataProvider, showNotification } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ForgotPassword extends React.Component {
  state = {
    checkModal: true,
    email: '',
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    const { updateState } = this.props;
    this.setState({
      checkModal: false,
    });
    updateState();
  };

  handleConfirm = () => {
    const { dataProvider, updateState, showNotification } = this.props;
    const { email } = this.state;
    const data = {
      email,
    };
    try {
      dataProvider.create('forgot-password', { data }).then(() => {
        showNotification('A mail has been sent to your registered mail account', 'info');
        updateState();
      });
    } catch (err) {
      showNotification(err.message, 'error');
    }
  };

  render() {
    const { checkModal, email } = this.state;
    return (
      <Modal open={checkModal}>
        <Dialog
          open
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                required
                id="outlined-email-input"
                label="Email"
                name="email"
                fullWidth
                value={email}
                onChange={this.handleChange}
                autoComplete="email"
                margin="normal"
                variant="outlined"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleConfirm}>
              <Typography color="primary">Confirm</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    );
  }
}
ForgotPassword.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};
export default compose(withDataProvider)(
  connect(
    null,
    { showNotification },
  )(ForgotPassword),
);
